import ReactGA from 'react-ga4'
import React, { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import {
  fetchArticleBySlug,
  fetchChapterBySlug,
} from '../services/articleSevices'
import { Typography, Box, Skeleton } from '@mui/material'
import Error from './Error'
import Article from './Article'
import usePageTracking from '../services/usePageTracking'
import ArticleCard from './ArticleCard'
const ArticleDetail = () => {
    usePageTracking()
  // useEffect(() => {
  //   ReactGA.send({
  //     hitType: 'pageview',
  //     page: '/articles/:slug',
  //     title: 'Specific Article Detail Page',
  //   })
  // })
  const { slug } = useParams()
  const location = useLocation()
  const [article, setArticle] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  // Determine if we're viewing an article or chapter based on the URL
  const isArticle = location.pathname.startsWith('/articles/')

  useEffect(() => {
    const getArticle = async () => {
      try {
        // Choose the appropriate fetch function based on content type
        const fetchFunction = isArticle
          ? fetchArticleBySlug
          : fetchChapterBySlug
        const data = await fetchFunction(slug)
        setArticle(data)
        // Update page title for better SEO and user experience
        document.title = isArticle
          ? `${data.title || 'Article Detail'}`
          : `${data.chapterName || 'Chapter Detail'}`
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    }

    getArticle()
  }, [slug, isArticle])

  if (loading) {
    // return <Typography>Loading123...</Typography>
    return (
      <Box sx={{ padding: 2 }}>
        <Skeleton variant='text' width='80%' height={40} />
        <Skeleton
          variant='text'
          width='60%'
          height={30}
          sx={{ marginTop: 2 }}
        />
        <Skeleton
          variant='rectangular'
          width='100%'
          height={400}
          sx={{ marginTop: 2 }}
        />
      </Box>
    )
  }
  if (error) {
    return <Error error={error} />
  }
  if (!article) {
    return null // show skeleton or some other loading indicator, informing the user via console and pop-up
  }
  if (isArticle) {
    return <Article article={article} />
  }
  // For chapters, we might want to create a separate Chapter component
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant='h4' gutterBottom>
        {article.chapterName}
      </Typography>
      {article.articles && article.articles.length > 0 ? (
        article.articles.map((article) => (
          <ArticleCard key={article.id} article={article}/>
        ))
      ) : (
        <Typography>No articles found in this chapter.</Typography>
      )}
    </Box>
  )
}
export default ArticleDetail
// <Paper sx={{ margin: 2, padding: 2 }} id={`article-${article.id}`}>
//     <Box display="flex" justifyContent="space-between" alignItems="center"></Box>
