import axios from "axios";

const baseUrl = process.env.REACT_APP_BACKEND_URL;
// const api = axios.create({
//     baseURL: baseUrl
// });

// export const searchArticles = (query) => api.get(`/search?query=${query}`);
// export const searchFilteredArticles = (params) => api.get('/search', { params });
// export const autoCompleteTitle = (prefix) => api.get(`/autocomplete?prefix=${prefix}`);

export const fetchArticles = async () => {
  const response = await fetch(baseUrl + '/api/v1/articles/')

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};
export const fetchArticleById = async (id) => {
  const response = await fetch(`${baseUrl}/api/v1/articles/${id}`)
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

export const searchArticles = async (query) => {
  const response = await fetch(
    `${baseUrl}/api/v1/articles/search?query=${encodeURIComponent(query)}`
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};
// Generic fetch function for all entity types
const fetchEntityBySlug = async (entityType, slug) => {
  const response = await fetch(`${baseUrl}/api/v1/${entityType}/slug/${slug}`);
  
  if (!response.ok) {
    if (response.status === 404) {
      throw new Error(
        `404, No such ${entityType.slice(0, -1)} exists 🤕 \n We're sorry. The Web address you entered is not a functioning page on our site.`
      );
    }
    if (response.status === 500) {
      throw new Error("500, internal server error");
    }
    throw new Error(
      `Network response was not ok, while fetching: "${slug}", HTTP Status ${response.status}: ${response.statusText}`
    );
  }
  
  return await response.json();
};

// Specific entity fetch functions using the generic function
export const fetchArticleBySlug = (slug) => fetchEntityBySlug('articles', slug);

export const fetchDecisionBySlug = (slug) => fetchEntityBySlug('decisions', slug);

export const fetchGuideBySlug = (slug) => fetchEntityBySlug('guides', slug);

export const fetchChapterBySlug = (slug) => fetchEntityBySlug('chapters', slug);
