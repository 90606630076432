import ReactGA from 'react-ga4'
import React, { useMemo, useState, useEffect } from 'react'
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  ScrollRestoration,
  // Navigate
} from "react-router-dom";
import {
  Box,
  CssBaseline,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material'
import './services/interceptor'
import Error from './components/Error'
import ArticleDetail from './components/ArticleDetail'
import List from './components/List'
import DecisionList from './components/decisions/DecisionList'
import Homepage from './components/homepage2/pages/Homepage'
import NavBar from './components/homepage2/components/NavBar'
import Footer from './components/homepage2/components/Footer'
import DecisionDetail from './components/decisions/DecisionDetail'
import GuideList from './components/guides/GuideList'
import GuideDetail from './components/guides/GuideDetail'
//import PDFViewer from './components/pdf-viewer/PDFViewer'
import GlobalSearch from './components/GlobalSearch'
import WarningPopup from './components/WarningPopup'
import usePageTracking from './services/usePageTracking'
import RenderHTML from './components/RenderHTML'
import CookiePolicy from './components/CookiePolicy'
import PrivacyPolicy from './components/PrivacyPolicy'
import TermsAndConditions from './components/TermsAndConditions'
const Root = () => {
  usePageTracking()
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <CssBaseline />
      <WarningPopup />
      {/* <Container>
      <Typography variant="h2">GCC Laws Search Portal</Typography>
    </Container> */}
      <NavBar />
      {/* We can't see footer unless we scroll once - to make these changes we've added such code */}

      <Box
        sx={{
          flex: 1,
        }}
      >
        <Outlet />
        <ScrollRestoration />
      </Box>
      <Footer />
    </Box>
  );
}

const App = () => {
  // const [loading, setLoading] = useState(true) // CircularProgress
  useEffect(() => {
    ReactGA.initialize('G-MWD8DMQS5H') // process.env.REACT_APP_GOOGLE_ANALYTICS_MERCHANT_ID
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname })

    // CircularProgress: 1-second load time, you 
    // const timer = setTimeout(() => {
    //   setLoading(false)
    // }, 1000)
    // return () => clearTimeout(timer) // Clean up the timer

    // instead of adding a fake delay of 1 second, we can make api call and show progress bar
    // const [loading, setLoading] = useState(true) // State for loading
    // const [data, setData] = useState(null) // Placeholder for your actual data
    // useEffect(() => {
    //   ReactGA.initialize('G-MWD8DMQS5H')
    //   ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
    //   Simulate fetching data from an API or some async process
    //   fetchData()
    //   async function fetchData() {
    //     try {
    //       Simulate API call or data loading
    //       const result = await fetch('/api/gcc-tax-laws') // Replace with your actual API
    //       const data = await result.json()
    //       setData(data) // Update the state with the fetched data
    //       setLoading(false) // Set loading to false once data is loaded
    //     } catch (error) {
    //       console.error('Error fetching data', error)
    //       setLoading(false) // In case of error, stop loading
    //     }
    //   }
    // }, [])


  }, [])

  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          path: '/',
          element: <Root />,
          errorElement: <Error />,
          children: [
            // {
            //   path: '/api',
            //   element: (
            //     <Navigate
            //       to='/api/fintegerllp/decreehub/api/swagger-ui/index.html'
            //       replace
            //     />
            //   ),
            // },
            {
              path: '/',
              element: <Homepage />,
            },
            {
              path: '/search',
              element: <GlobalSearch />,
            },
            {
              path: '/signup',
              element: <Error />,
            },
            // {
            //   path: "/lawSearch",
            //   element: <List />,
            // },
            // {
            //   path: "/decisionSearch",
            //   element: <DecisionList />,
            // },
            // {
            //   path: "/guideSearch",
            //   element: <GuideList />,
            // },
            {
              path: '/search/:country/Tax-Laws',
              element: <List />,
            },
            // instead of duplicating same component, /search/:country/:type
            {
              path: '/search/:country/Decisions',
              element: <DecisionList />,
            },
            // instead of duplicating same component, /search/:country/:type
            {
              path: '/search/:country/Guides',
              element: <GuideList />,
            },
            // instead of duplicating same component, /search/:country/:type
            {
              path: '/search/:country/E-Forms',
              element: <Error />,
            },
            {
              path: '/articles/:slug',
              element: <ArticleDetail />,
            },
            {
              path: '/chapters/:slug',
              element: <ArticleDetail />,
            },
            {
              path: '/decisions/:slug',
              element: <DecisionDetail />,
            },
            {
              path: '/guides/:slug',
              element: <GuideDetail />,
            },
            // {
            //   path: '/:render',
            //   element: <RenderHTML />,
            // },
            {
              path: '/cookie-policy',
              element: <CookiePolicy />, // Route for Cookie Policy
            },
            {
              path: '/privacy-policy',
              element: <PrivacyPolicy />, // Route for Cookie Policy
            },
            {
              path: '/terms-and-conditions',
              element: <TermsAndConditions />, // Route for Cookie Policy
            },
            {
              path: '*',  // Add this catch-all route
              element: <Error />,
            },
//            {
//              path: '/pdf',
//              element: <PDFViewer />,
//            },
          ],
        },
      ]),
    []
  )
  // if (loading) {
  //   return (
  //     <Box
  //       sx={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //         height: '100vh',
  //         backgroundColor: '#232536', // Custom background color
  //         textAlign: 'center',
  //       }}
  //     >
  //       <CircularProgress
  //         sx={{
  //           color: 'white', // Customize the loader color
  //           marginBottom: '20px',
  //         }}
  //       />
  //       <Typography
  //         variant='h6'
  //         sx={{
  //           color: 'white', // Customize the text color
  //         }}
  //       >
  //         Loading GCC tax Laws
  //       </Typography>
  //     </Box>
  //   )
  // }

  return <RouterProvider router={router} />
}
export default App
