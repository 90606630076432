import React, { useState, useEffect, useCallback, useRef } from "react";
// import React, { forwardRef } from "react"; used if you want to print Article by clicking button present on ArticleCard component

import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import MailRoundedIcon from "@mui/icons-material/MailRounded";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";
import SortByAlphaRoundedIcon from "@mui/icons-material/SortByAlphaRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

import {
  Box,
  IconButton,
  Typography,
  Paper,
  CircularProgress,
  Skeleton,
} from "@mui/material";

import _ from "lodash";
// spread operator (...) might creates a shallow copy of the array. When dealing with nested objects, such as the data array within the content array, this can lead to unintentional mutations of the original data structure, for reference: sortDefinitions, to create a deep copy of the article.content we can use a utility function to deep clone objects, such as lodash's cloneDeep method.

import {
  handleEmail,
  handleOpenInNewTab,
} from "../../services/articleCardHandlers";

import { parseHtmlSanitizeAddTargetToLinks } from "../../services/utils";

// import NestedOrderedList from "./NestedOrderedList";
import FeedbackForm from "../FeedbackForm";
// import CustomTable from "./CustomTable";

// To enable the PrintRoundedIcon button in the ArticleCard component to print the Article component within it, you need to pass a reference of the Article component to the print function. Utilize a ref to get the Article component's HTML content.

const DecisionArticle =
  // forwardRef(
  ({ article, searchQuery }) =>
    // ,ref
    {
      const [decisionContent, setDecisionContent] = useState(null);
      const [loading, setLoading] = useState(false);
      const [isFeedbackFormOpen, setIsFeedbackFormOpen] = useState(false);
      const componentRef = useRef();
      const baseUrl = process.env.REACT_APP_BACKEND_URL;

      // Fetch decision content with caching
      const fetchDecisionContent = async (slug) => {
        // Check if content exists in localStorage
        const cachedContent = localStorage.getItem(slug);
        if (cachedContent) {
          setDecisionContent(JSON.parse(cachedContent));
          return;
        }

        // if (decisionCache[slug]) {
        //   setDecisionContent(decisionCache[slug])
        //   return
        // }

        setLoading(true);
        try {
          const response = await fetch(`${baseUrl}/api/v1/decisions/slug/${slug}`);
          if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
          }
          const data = await response.json();
          // setDecisionCache((prevCache) => ({ ...prevCache, [slug]: data }))

          // Cache content in localStorage
          localStorage.setItem(slug, JSON.stringify(data));
          setDecisionContent(data);
        } catch (err) {
          console.error("Error fetching decision content:", err.message);
        } finally {
          setLoading(false);
        }
      };

      useEffect(() => {
        fetchDecisionContent(article.slug);
      }, [article.slug]);

      const handleFeedbackFormToggle = () => {
        setIsFeedbackFormOpen(!isFeedbackFormOpen);
      };

      const handlePrint = useCallback(() => {
        if (componentRef.current) {
          const printWindow = window.open("", "_blank");
          printWindow.document.write("<html><head><title>GCC Tax Laws</title>");

          // Copy all styles from the current document to the print document
          const styleSheets = document.styleSheets;
          for (let i = 0; i < styleSheets.length; i++) {
            if (styleSheets[i].href) {
              printWindow.document.write(
                `<link rel="stylesheet" type="text/css" href="${styleSheets[i].href}">`
              );
            } else {
              try {
                let css = "";
                for (let j = 0; j < styleSheets[i].cssRules.length; j++) {
                  css += styleSheets[i].cssRules[j].cssText;
                }
                printWindow.document.write(`<style>${css}</style>`);
              } catch (e) {
                console.warn("Error accessing stylesheet", e);
              }
            }
          }

          printWindow.document.write("</head><body>");
          printWindow.document.write(componentRef.current.innerHTML);
          // printWindow.document.write(ref!==null?ref.current.innerHTML:componentRef.current.innerHTML);
          printWindow.document.write("</body></html>");
          printWindow.document.close();
          // printWindow.focus();
          printWindow.print();
          // Attach the onafterprint event to close the print window
          printWindow.onafterprint = () => {
            printWindow.close();
          };
        }
        // }, [ref]);
        // }, []);
      }, [componentRef]);

      // let say we have article.title="Article 1 - Definitions <a href="/articles/uae-cit-article-4" target="_blank">Article 4</a>." Now while rendering <Article>, when we click on this link, it opens the link in the same tab, although target=_blank is mentioned
      // Likely due to how React handles dangerouslySetInnerHTML. The event listeners might not be attached correctly to the sanitized HTML elements. We can use the useEffect hook to manually add event listeners to the links after the component mounts or updates
      // OR we can use html-react-parser or htmlparser2 npm-package
      // useEffect(() => {
      //   const links = componentRef.current.querySelectorAll('a[target="_blank"]'); //DOMPurify.sanitize() removed the target attribute, you can check by doing inspect in browser, so this will work: querySelectorAll('a')
      //   links.forEach((link) => {
      //     link.addEventListener("click", (e) => {
      //       e.preventDefault();
      //       window.open(link.href, "_blank");
      //     });
      //   });
      //   console.log(links);
      // Clean Up callback: The event listeners are cleaned up to prevent memory leaks.
      // cleanup callback in the useEffect hook is called:
      // 1. When the component is unmounted.
      // 2. Before the effect is re-executed due to changes in its dependencies - When sortedContent changes, which will trigger the effect to re-run
      // Memory leaks can happen if:
      // 1. Event listeners are not properly removed when the component is unmounted or when dependencies change.
      // 2. If the effect re-runs without cleaning up previous event listeners, you might end up with multiple event listeners attached to the same element, leading to unexpected behavior and increased memory usage.
      //   return () => {
      //     links.forEach((link) => {
      //       link.removeEventListener("click", (e) => {
      //         e.preventDefault();
      //         window.open(link.href, "_blank");
      //       });
      //     });
      //   };
      // }, [sortedContent]);

      // useEffect will work if you apply proper querySelector, for example you have written find all anchor tags who have target="_blank", but if you try to inspect the article in browser, you won't find the target attribute in anchor tag, maybe becuase of DOMPurify.sanitize()
      // Method 2 Trying html-react-parser -  avoiding potential memory leaks from improper handling of event listeners
      if (loading) {
        return (
          <Box sx={{ padding: 2, margin: 2 }}>
            <Skeleton variant="rectangular" width="100%" height={200} />
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width="80%" />
          </Box>
        );
        // return (
        //   <Box display='flex' justifyContent='center' mt={3}>
        //     <CircularProgress />
        //   </Box>
        // )
      }
      return (
        <Paper
          ref={componentRef}
          // ref={ref}
          sx={{ padding: 2, margin: 2 }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "flex-start",
              }}
            >
              <Typography variant="h5">
                {parseHtmlSanitizeAddTargetToLinks(article.name, searchQuery)}
              </Typography>
              <Typography variant="subtitle1">
                {parseHtmlSanitizeAddTargetToLinks(article.title, searchQuery)}
              </Typography>
            </Box> */}
            <Box>
              <IconButton
                aria-label="Report Broken Links in this article"
                title="Report Broken Links in this article"
                onClick={handleFeedbackFormToggle}
              >
                <ReportRoundedIcon
                  // fontSize="large"
                  color="error"
                />
              </IconButton>

              <IconButton
                aria-label="Print this article"
                title="Print this article"
                onClick={handlePrint}
              >
                <PrintRoundedIcon
                  // fontSize="large"
                  color="primary"
                />
              </IconButton>

              <IconButton
                aria-label="Mail this article"
                title="Mail this article"
                onClick={() => handleEmail(article)}
              >
                <MailRoundedIcon color="primary" />
              </IconButton>

              <IconButton
                aria-label="Open this article in new tab"
                title="Open this article in new tab"
                onClick={() =>
                  handleOpenInNewTab(article, `/decisions/${article?.slug}`)
                }
              >
                {/*fontSize: (default 24px), OR large, (default medium), small, string */}
                <OpenInNewRoundedIcon
                  // sx={{ fontSize: 32 }}
                  color="primary"
                />
              </IconButton>
            </Box>
          </Box>
          {isFeedbackFormOpen && (
            <FeedbackForm
              articleId={article.slug}
              onClose={handleFeedbackFormToggle}
            />
          )}
          {decisionContent ? (
            <Box key={article.id} mt={2}>
              <Typography>
                {parseHtmlSanitizeAddTargetToLinks(
                  decisionContent.content,
                  searchQuery
                )}
              </Typography>
            </Box>
          ) : (
            <Typography>No content available.</Typography>
          )}
        </Paper>
      );
    };
export default DecisionArticle;
